import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

export const Go3 = css`
  &.${APP_SLUGS.GO3} {
    .navbar-container {
      min-height: 4rem;
    }
    .navbar-logos {
      img {
        height: 30px;
      }
    }
    .login-active,
    .btn-logout {
      /* display: none; */
      font-size: 15px;
      box-shadow: 0 0 5px 3px #fa6200;
      border: 0;
    }
    .navbar-home {
      background: #000000bf;
    }
    .my-referral-table {
      overflow: hidden;
      thead {
        background: rgb(243 121 31) !important;
      }
      tbody {
        td {
          color: #202020;
        }
      }
    }
    .top-tournament-full-width {
      .game-details {
        color: #fa6200;
      }
      .swiper-pagination-bullet-active {
        background: #fa6200;
      }
      .rank-btn {
        box-shadow: 0 0 5px 3px #fa6200;
        background-color: #f3791f !important;
        color: #ffffff !important;
      }
      .text-play {
        box-shadow: 0 0 5px 3px #8fb44ccc;
        background: #75aa16 !important;
        color: #ffffff !important;
      }
    }
    .list-group-item,
    .rank-list-container-first {
      background: #202020 !important;
    }
    .ticket-status-bar {
      .ticket-status {
        background-color: #202020 !important;
      }
      .desktop-plus {
        display: none !important;
      }
    }
    .past-day-img {
      filter: grayscale(100%);
    }
    .day-container {
      .img-inside-container {
        &.current {
          .img {
            width: 80px !important;
          }
        }
        .img.upcoming {
          width: 60px !important;
        }
      }
    }
    .ticket-history-body {
      .header-style {
        background-color: #f3791f !important;
      }
      background: #202020 !important;
    }
    .redeem {
      background: #202020 !important;
    }
    .refer-text {
      color: #202020 !important;
      background-color: #8fb44c !important;
      box-shadow: none !important;
    }
    .redeem-card {
      background: #75aa16 !important;
      margin-top: 40px;
    }
    .list-heading {
      .text-style {
        color: #202020 !important;
      }
    }
    .rank-list-card {
      .list-heading {
        padding-bottom: 0 !important;
      }
    }
    .invite-text {
      color: #f3791f !important;
    }
    .my-tab {
      background-color: #202020 !important;
      .button-style {
        height: 100% !important;
        width: 100% !important;
        margin: 0 !important;
      }
    }
    .tab-active {
      background-color: #f3791f !important;
      color: #202020 !important;
    }
    .tab-inactive {
      background-color: #202020 !important;
      color: #ffffff !important;
    }
    .my-card {
      background-color: #202020 !important;
      color: #f3791f !important;
    }

    .leaderboard-popup-item {
      .dummy-rank-list {
        .row {
          padding: 5%;
          .col-items {
            color: #d9d9d9 !important;
            > div {
              background: #d9d9d9 !important;
            }
          }
        }
        .unlock-style {
          background: #fa6200 !important;
          box-shadow: 0 0 5px 3px #fa6200 !important;
        }
      }
      .img-style {
        border: 2px solid #fa6200;
        height: 80px;
      }
      > * {
        color: #d9d9d9 !important;
      }
      .close_wrapper {
        svg {
          fill: #fa6200 !important;
        }
      }
      background: #000000;
      .modal_header {
        background: #000000;
      }
      .drag-handle:after {
        background-color: #fa6200;
      }
      .prizes-slider-item {
        background-color: #202020 !important;
        .icon {
          background: #000000 !important;
          svg {
            fill: #d9d9d9;
          }
        }
      }
      .rank-list-card {
        .label-no-ranks {
          color: #fa6200 !important;
        }
        > *,
        .text-style-transaction {
          color: #d9d9d9 !important;
        }
      }

      .prizes-slider-container {
        border: 0 !important;
      }
      .rank-list-container {
        box-shadow: none !important;
      }
      .rank-list-container,
      .rank-list-container-first {
        .list-group-item {
          background-color: #202020 !important;
        }
      }

      .prize-list-container {
        background-color: #202020 !important;
        .prize-calculator-item {
          .label-prize {
            b {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
            }
          }
        }
        .label-rank {
          color: #d9d9d9 !important;
        }
        .label-prize {
          color: #d9d9d9 !important;
        }
      }
    }
    .tournament-list-popup {
      .small-card .border-style {
        border-bottom: 0.1px solid #000000;
      }
      .img-style {
        border: 1px solid #fa6200 !important;
      }
      .title-md {
        color: #d9d9d9;
      }
      .gamename-style,
      .title-header {
        color: #fa6200;
      }
      .close_wrapper {
        svg {
          fill: #fa6200 !important;
        }
      }
      .small-card {
        background-color: #2b2b2b !important;
        .name-sty {
          color: #d9d9d9 !important;
          > * {
            color: #d9d9d9 !important;
          }
          svg {
            fill: #d9d9d9 !important;
          }
        }
        .rank-btn {
          background: #424242 !important;
          .rank-icon {
            fill: #fa6200;
          }
        }
        .play-btn-browse {
          background: #000000 !important;
          color: #fa6200;
          .play-icon {
            fill: #fa6200;
          }
        }
      }
    }
    .profile-page {
      .top-bar-wrapper {
        .token-sty {
          gap: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .back-button-wrapper {
        svg {
          fill: #fa6200 !important;
          stroke: #fa6200 !important;
        }
      }
      .user-profile-customization {
        .support-style {
          background: #fa6200 !important;
          svg {
            fill: #000000 !important;
          }
        }
      }
      .profile-page-body {
        .tournament-history-btn,
        .show-all-text {
          color: #fa6200 !important;
        }
        .profile-history-list {
          .profile-history-wrapper {
            background: #202020;
          }
          .history-border {
            color: #fa6200;
            .list-group-item {
              border-bottom: 0 !important;
            }
          }
          .rank-list-avatar {
            border: 2px solid #fa6200;
          }
        }
      }
    }
    .name-update-modal {
      input {
        color: #ffffff;
        &::placeholder {
          color: #ffffff;
          opacity: 0.8; /* Firefox */
        }
      }
    }
    .tournament-info-popup {
      .drag-handle::after {
        background: #fa6200 !important;
      }
      .close_wrapper {
        > * {
          fill: #fa6200 !important;
        }
      }
      .tournament-info-list {
        .header-line {
          color: #fa6200 !important;
        }
        .white-card-design {
          background-color: #202020 !important;
          .rank-style {
            color: #fa6200 !important;
          }
        }
      }

      .leaderboard-body {
        .header-line {
          color: #fa6200 !important;
        }
        .avatar-row {
          .competitor-name {
            color: #fa6200 !important;
          }
          .avatar1,
          .avatar {
            border: 1px solid #fa6200;
          }
        }
      }

      .achievement-history-list-item {
        background: #202020 !important;
        .text-style-transaction,
        .text-style-transaction,
        .body-xs {
          color: #fa6200 !important;
        }
      }
    }
    .tournament-history-page {
      .back-button-wrapper {
        svg {
          fill: #fa6200 !important;
          stroke: #fa6200 !important;
        }
      }
      .header-style {
        color: #fa6200 !important;
      }
      .white-card {
        background-color: #202020;
        > * {
          color: #fa6200 !important;
        }
      }
      .street-text {
        color: #fa6200;
      }
      .txt-style,
      .won-style {
        color: #ffffff;
      }
      .second-row {
        .label {
          color: #ffffff;
        }
        .label-value {
          color: #fa6200;
        }
      }
    }
    .custom-popup-width {
      .close_wrapper {
        svg {
          fill: #ffffff !important;
        }
      }
    }
    .modal-content {
      .text-header {
        color: #d9d9d9 !important;
      }
      background: #202020 !important;
      color: #d9d9d9 !important;
      .btn-style {
        color: #d9d9d9 !important;
        background-color: #f3791f !important;
      }
    }
    .GO3-modal-content {
      background: #202020 !important;
      .modal-title {
        color: #fa6200 !important;
      }
      .btn-close {
        /* background: #fa6200 !important; */
      }
    }
    .browse-game-list {
      .browse-games {
        padding: 0;
      }
      .browse-games-rectangular-card {
        background-color: rgb(77, 77, 77) !important;
        /* box-shadow: rgb(40 10 3) -9px -20px 42px 9px inset !important; */
        border: 1px solid #fa62007d !important;
        img {
          border: 1px solid #fa6200 !important;
        }
      }
    }
    .tournament-list-item {
      .play-btn-browse {
        > div {
          gap: 2px !important;
        }
      }
    }
    .browse-games-rectangular-card {
      .participant-counter-row {
        .count {
          color: #fa6200 !important;
        }
      }
    }
    .heroes-info-wrapper {
      .rank-list-container {
        overflow-x: hidden;
      }
      .heroes-time-range {
        .not-active-button {
          color: #ffffff;
        }
        .daily,
        .weekly,
        .all_time,
        .monthly {
          display: none !important;
        }
      }
    }
    .avatar-update-modal {
      .modal-title {
        color: #000000;
      }
    }
    .footer-wrapper {
      .mobile-footer-page {
        margin-top: 30px;
        padding-bottom: 5rem;
      }
    }
    .browse-popup {
      .close_wrapper {
        svg {
          fill: #ffffff;
        }
      }
    }
    .desktop-play-footer {
      box-shadow: 0 0 5px 3px #8fb44ccc;
      background: #75aa16 !important;
      color: #ffffff !important;
      img {
        width: 20px !important;
        max-width: 20px !important;
      }
    }

    .button-orange {
      button {
        color: #ffffff !important;
      }
    }

    .heroes {
      .first-part {
        background: #f3791f !important;
      }
    }

    .rank-list-container {
      h5 {
        /* color: #ffffff !important; */
      }
    }

    .header-container {
      .title {
        color: #ffffff !important;
      }
    }

    .activated-button {
      color: #fa6200 !important;
    }

    .top-bar-wrapper {
      .fontHeadStyle,
      .fontSubStyle {
        color: #fa6200 !important;
      }
    }

    .page-wrapper {
      .top-bar {
        .top-bar-top {
          background: #000000 !important;
          box-shadow: none !important;
        }
      }
    }

    .custom-popup-container {
      .coming-soon-text,
      span {
        color: #fa6200 !important;
      }
    }

    .footer-content {
      /* display: none !important; */
      background-color: #000000 !important;
      a:not(.active-link) {
        > * {
          color: #fff;
          fill: #fff;
        }
      }
    }

    .headerInfoWrapper {
      .no-info {
        color: #fa6200 !important;
      }
    }

    .mission-page-container {
      .section-header {
        color: #fa6200 !important;
      }

      .square-card {
        .rounded-div {
          background: transparent !important;
        }
        box-shadow: none !important;
        border: none !important;
        background-color: #202020 !important;
        .card-title span {
          color: #ffffff !important;
        }
        .progressbar_wrapper {
          .small_circle {
            border: 7px solid #202020 !important;
          }
          .reward-img {
            transform: rotate(0deg);
          }
          .RCP {
            background-color: #0d0d0d !important;
          }
          .RCP__track {
            stroke: #202020 !important;
          }
          .RCP__value {
            color: #f3791f !important;
          }
        }

        .progress_bottom_curve {
          background-color: #0d0d0d !important;
          .timer {
            .clock_icon {
              padding-bottom: 5px !important;
            }
          }
          .time_counter_title,
          span {
            color: #ffffff !important;
          }
        }

        &.active-card {
          background: #75aa16 !important;
          .RCP {
            background-color: #ffffff !important;
          }
          .progress_bottom_curve {
            background-color: #75aa16 !important;
          }
          .small_circle {
            border: none !important;
          }
        }
      }
    }

    .active_progress_bottom_curve {
      background-color: #fa6200 !important;
    }

    .action_button {
      button {
        color: #000000 !important;
      }
    }

    .homepage-background,
    .userprofile-background {
      box-shadow: rgba(176, 71, 0, 1) 0 4px 12px !important;
    }

    .recommended-games {
      background-color: #000000 !important;
    }
    .half-style {
      background: linear-gradient(to bottom, rgba(176, 71, 0, 1), rgba(241, 109, 0, 1)) !important;
    }

    w3m-modal {
      display: block !important;
    }

    --w3m-z-index: 100005 !important;
  }
`;

export default Go3;
